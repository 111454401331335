function showErrors(error, formName) {
  const { status } = error.response;
  const { data } = error.response;
  if (status === 422) {
    this.$refs[formName].setErrors(data.errors);
  }
}

const imageDefaultPath = process.env.VUE_APP_URL_IMAGE_DEFAULT;

export { showErrors, imageDefaultPath };
